import axios from "axios";

export let headers = {
	"Tenant-Access-Token": "NjU0YTg1YjgzZGUzNDdlYjM1NWY2YmUwZTg3OWQyMjY=",
	"Tenant-Access-Key": "zzh5gsFN-vAE8A",
};

export const api = axios.create({
	baseURL: "https://tiltlabs-dev.confirmu.com",
	headers: {
		"Tenant-Access-Token": "NjU0YTg1YjgzZGUzNDdlYjM1NWY2YmUwZTg3OWQyMjY=",
		"Tenant-Access-Key": "zzh5gsFN-vAE8A",
	},
});

export const BASE_URL = "https://tiltlabs-dev.confirmu.com";
